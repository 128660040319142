/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
  background: rgba(19, 20, 40, 0.85);
  color: #fff;

  .footer-newsletter {
    padding: 50px 0;
    background: lighten($secondary, 61);
    text-align: center;
    font-size: 15px;
    color: $default;

    h4 {
      font-size: 24px;
      margin: 0 0 20px 0;
      padding: 0;
      line-height: 1;
      font-weight: 600;
      color: $secondary;
    }

    form {
      margin-top: 30px;
      background: #fff;
      padding: 6px 10px;
      position: relative;
      border-radius: 50px;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.06);
      text-align: left;

      input[type="email"] {
        border: 0;
        padding: 4px 8px;
        width: calc(100% - 100px);
      }

      input[type="submit"] {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border: 0;
        background: none;
        font-size: 16px;
        padding: 0 20px;
        background: $primary;
        color: $white;
        transition: 0.3s;
        border-radius: 50px;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
        &:hover {
          background: darken($primary, 10);
        }
      }
    }
  }

  .footer-top {
    padding: 60px 0 30px 0;
    background: $white;

    .footer-contact {
      h3 {
        font-size: 28px;
        margin: 0 0 10px 0;
        padding: 2px 0 2px 0;
        line-height: 1;
        text-transform: uppercase;
        font-weight: 600;
        color: $secondary;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-family: $font-primary;
        color: lighten($default, 10);
      }
      margin-bottom: 30px;
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
      color: $secondary;
      position: relative;
      padding-bottom: 12px;
    }

    .footer-links {
      margin-bottom: 30px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        i {
          padding-right: 2px;
          color: $primary;
          font-size: 18px;
          line-height: 1;
        }

        li {
          padding: 10px 0;
          &:first-child {
            padding-top: 0;
          }
          display: flex;
          align-items: center;
        }

        a {
          color: lighten($default, 20);
          transition: 0.3s;
          display: inline-block;
          line-height: 1;
          &:hover {
            text-decoration: none;
            color: $primary;
          }
        }
      }
    }

    .social-links {
      a {
        font-size: 18px;
        display: inline-block;
        background: $primary;
        color: $white;
        line-height: 1;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
        &:hover {
          background: darken($primary, 10);
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: $white;
  }

  .copyright {
    float: left;
  }

  .credits {
    float: right;
    font-size: 13px;
    a {
      transition: 0.3s;
    }
  }

  @media (max-width: 768px) {
    .footer-bottom {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .copyright,
    .credits {
      text-align: center;
      float: none;
    }

    .credits {
      padding-top: 4px;
    }
  }
}
