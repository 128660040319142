/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 9px 0;

  &.header-scrolled,
  &.header-inner-pages {
    background: rgba(19,20,40,0.85);
  }

  .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;

    a {
      color: #fff;
    }

    img {
      max-height: 40px;
    }
  }
}
