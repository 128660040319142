/*--------------------------------------------------------------
# services
--------------------------------------------------------------*/
.counts .content {
  padding: 30px 0;
}
.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #151515;
}
.counts .content p {
  margin-bottom: 0;
}
.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}
.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #4a6adc;
  float: left;
}
.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #151515;
  margin-left: 50px;
}
.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #3b3b3b;
}
.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #3b3b3b;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}
.counts .content .count-box a:hover {
  color: #626262;
}
.counts .image {
  background-size: cover;
  min-height: 400px;
}
@media (max-width: 991px) {
  .counts .image {
    text-align: center;
  }
  .counts .image img {
    max-width: 80%;
  }
}
@media (max-width: 667px) {
  .counts .image img {
    max-width: 100%;
  }
}